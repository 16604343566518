<!-- eslint-disable prettier/prettier -->
<template>
  <div class="row">
    <div class="col-12">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div style="display: flex; justify-content: space-between">
            <h4 class="card-title">Control de tickets</h4>
          </div>
        </template>

        <div>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <el-select
              class="select-primary mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <fg-input>
              <el-input
                type="search"
                class="mb-3"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </div>
          <el-table style="width: 100%" :data="queriedData" :row-class-name="rowClassChange">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="180" fixed="right" label="Acciones">
              <template v-slot:default="props">
                <div class="table-actions">
                  <n-button
                    @click="props.row.opcionesPDF.click()"
                    class="edit"
                    type="info"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#pdfModal"
                  >
                    <i class="fa fa-file-pdf-o"></i>
                  </n-button>
                  <n-button
                    @click="props.row.editarSeguimiento.click()"
                    class="edit"
                    type="success"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#seguimientoModal"
                  >
                    <i class="fa fa-inbox"></i>
                  </n-button>
                  <n-button
                    @click="props.row.editarTicket.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#updateModal"
                  >
                    <i class="fa fa-calendar"></i>
                  </n-button>
                  <n-button
                    @click="props.row.eliminarButton.click()"
                    class="remove"
                    type="danger"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <!-- Modal ACTUALIZAR TICKETS -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateModalLabel">Editar ticket</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6 mt-2">
                      <!--<fg-input v-model="fecha_inicio" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>-->
                      <!--<fg-input v-model="horas_contratadas" label="Horas a contratar" type="number" placeholder="Ingresa la cantidad de horas"></fg-input>-->
                      <fg-input v-model="observacionesEdit" label="Observaciones" type="text" placeholder="Observaciones del ticket"></fg-input>
                      <fg-input v-model="problemaEdit" label="Problema" type="text" placeholder="Problemática del ticket"></fg-input>
                      <fg-input v-model="solucionEdit" label="Solucion" type="text" placeholder="Solución del ticket"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <div class="row">

                        <div class="col-md-6 d-flex flex-column">
                          <label class="form-label">Selecciona un técnico</label>
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Tecnico"
                            v-model="selectTecEdit"
                          >
                            <el-option
                              v-for="option in selectTec.tecnicos"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>

                        <div class="col-md-6 d-flex flex-column">
                          <label class="form-label">Cambiar estado</label>
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Tecnico"
                            v-model="selectEstadoEdit"
                          >
                            <el-option
                              v-for="option in selectEstado.estados"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="actualizarTicket">Actualizar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- FIN Modal -->



      <!-- Modal SEGUIMIENTO TICKETS -->
      <div class="modal fade" id="seguimientoModal" tabindex="-1" role="dialog" aria-labelledby="seguimientoModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="seguimientoModalLabel">Seguimiento del ticket</h5>
                <button @click="limpiarNuevoSeguimiento" type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <div class="flex items-center mb-4">
                  <input v-model="nuevoSeguimiento" type="text" placeholder="Escribe el comentario" class=" col-md-8 w-full border border-gray-300 rounded-lg p-2 mr-2">
                  <n-button type="info" @click="agregarSeguimiento"><i class="fa fa-plus mr-1"></i> Agregar seguimiento</n-button>
                </div>

                <!-- Lista de comentarios de seguimiento -->
                <div>
                  <h2 class="text-lg font-semibold mb-2">Comentarios de Seguimiento</h2>
                  <ul style="max-height: 500px; overflow-y: auto;">
                    <li v-for="(comentario, index) in seguimientos.slice().reverse()" :key="index" class="bg-gray-100 p-2 rounded-md mb-2">
                      <p>{{ comentario.texto }}</p>
                      <p class="text-gray-500 text-sm">{{ comentario.fechaHora }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <!--<div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="actualizarTicket">Actualizar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>-->
            </div>
          </div>
        </div>
      <!-- FIN Modal -->


      <!-- Modal Opciones PDF -->
      <div class="modal fade" id="pdfModal" tabindex="-1" role="dialog" aria-labelledby="pdfModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="pdfModalLabel">Reporte del ticket No. <b>{{ idticketPDF }}</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="flex text-center mt-2 mb-2">
                    <n-button class="mr-2" type="primary" @click="previsualizarReporte" size="lg" round><i class="fa fa-eye mr-1"></i> Ver reporte</n-button>
                    <n-button type="info" @click="enviarReporte" size="lg" round><i class="fa fa-envelope mr-1"></i> Enviar reporte</n-button>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- FIN Modal -->


        <template v-slot:footer>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </n-pagination>
          </div>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
export default {
  components: {
    NPagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      loadingSMTP: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["idticket", "poliza", "fecha_creacion", "estado", "idcliente", "idtecnico"],
      tableColumns: [
        {
          prop: "idticket",
          label: "Num. ticket",
          minWidth: 155,
        },
        {
          prop: "poliza",
          label: "Num. poliza",
          minWidth: 200,
        },
        {
          prop: "fecha_creacion",
          label: "Fecha creacion",
          minWidth: 200,
        },
        {
          prop: "estado",
          label: "Estado",
          minWidth: 130,
        },
        {
          prop: "observaciones",
          label: "Observaciones",
          minWidth: 230,
        },
        {
          prop: "problema",
          label: "Problema",
          minWidth: 200,
        },
        {
          prop: "solucion",
          label: "Solucion",
          minWidth: 200,
        },
        {
          prop: "idcliente",
          label: "Cliente",
          minWidth: 200,
        },
        {
          prop: "idtecnico",
          label: "Tecnico",
          minWidth: 150,
        },
      ],
      selectTec: {
        simple: "",
        tecnicos: [],
        multiple: "ARS",
      },
      selectEstado: {
        simple: "",
        estados: [
          { value: "Abierto", label: "Abierto" },
          { value: "Pausado", label: "Pausado" },
          { value: "Cerrado", label: "Cerrado" },
        ],
        multiple: "ARS",
      },
      observacionesEdit: "",
      problemaEdit: "",
      solucionEdit: "",
      selectTecEdit: "",
      selectEstadoEdit: "",
      idticketEdit: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      idticketSeguimiento: "",
      nuevoSeguimiento: "",
      seguimientos: [],
      idticketPDF:"",
    };
  },
  created() {
    /*this.obtenerCategorias();
    this.obtenerClientes();
    this.obtenerPolizas();*/
    this.apiUrl = getApiUrl();
    this.obtenerTecnicos();
    this.obtenerTickets();
  },
  methods: {
    //seguimiento modal
    limpiarNuevoSeguimiento() {
      this.nuevoSeguimiento = '';
    },
    handleSeguimiento(idticket) {
      this.idticketSeguimiento = idticket;
      this.obtenerSeguimiento(idticket);
    },
    async agregarSeguimiento() {
      if (this.nuevoSeguimiento.trim() !== '') {
        //const fechaHoraActual = new Date().toISOString().slice(0, 19).replace('T', ' ');
        
        // POST asincróno utilizando Axios
        try {
          const Data = {
            //fecha_hora: fechaHoraActual,
            comentario: this.nuevoSeguimiento,
            idticket: this.idticketSeguimiento
          }

          if (this.nuevoSeguimiento !== "") {
            const response = await axios.post(`${this.apiUrl}/register/seguimiento`, Data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });
          
            if (response.status === 200) {
              this.runToast("top-right", "success", "Seguimiento agregado exitosamente");
              
              this.seguimientos.unshift(response.data); // Seguimiento al principio de la lista
              
              this.nuevoSeguimiento = ''; // Limpiar el input después de agregar el seguimiento
              
              this.obtenerSeguimiento(this.idticketSeguimiento); // Actualizar la lista de seguimientos después de agregar uno nuevo
            }

          } else {
            this.runToast("top-right", "danger", "Faltan datos para el registro");
          }
        } catch (error) {
          //console.error('Error al agregar seguimiento:', error);
          this.runToast("top-right", "danger", "Error al agregar seguimiento");
        }
      }
    },
    async obtenerSeguimiento(idticket) {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/seguimiento/${idticket}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.seguimientos = response.data.map(seguimiento => ({
          texto: seguimiento.comentario,
          fechaHora: this.formatoFecha(seguimiento.fecha_hora),
        }));
      } catch (error) {
        console.error('Error al obtener seguimientos:', error);
      }
    },
    formatoFecha(fechaHora) {
      const dateObj = new Date(fechaHora);
      const dia = dateObj.getDate();
      const mes = dateObj.getMonth() + 1; // Los meses son indexados desde 0
      const anio = dateObj.getFullYear();
      const horas = dateObj.getHours();
      const minutos = dateObj.getMinutes();

      // Agregar ceros a la izquierda si es necesario
      const diaFormato = dia < 10 ? `0${dia}` : dia;
      const mesFormato = mes < 10 ? `0${mes}` : mes;
      const horasFormato = horas < 10 ? `0${horas}` : horas;
      const minutosFormato = minutos < 10 ? `0${minutos}` : minutos;

      return `${diaFormato}-${mesFormato}-${anio} ${horasFormato}:${minutosFormato}`;
    },
    //Cambiar color de fila en tabla cuando las horas restantes sean menores a 10
    rowClassChange({ row }) {
      /*const horasPart = row.horas_restantes.split(":");
      const horasRest = parseInt(horasPart[0]);*/
      if (row.estado === "Pausado") {
        return "table-warning";
      } else if (row.estado === "Cerrado") {
        return "table-success";
      } else {
        return "";
      }
    },
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    handlePDF(tickets) {
      this.idticketPDF = tickets.idticket;
    },
    async previsualizarReporte() {
      try {
        const response = await axios.post(`${this.apiUrl}/reporte/pdf-ticket/preview/${this.idticketPDF}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        // Obtener la URL del PDF desde la respuesta del backend
        const pdfUrl = response.data.pdfUrl;
        //console.log(pdfUrl)
        // Redireccionar al usuario para descargar o visualizar el PDF
        window.open(pdfUrl, '_blank');
      } catch (error) {
          console.error('Error al previsualizar el ticket:', error);
      }
    },
    async enviarReporte() {
      try {
        this.loadingSMTP = true;

        if (this.loadingSMTP) {
          this.runToast("top-right", "warning", "Cargando...");
        }

        const response = await axios.post(`${this.apiUrl}/reporte/pdf-ticket/enviar/${this.idticketPDF}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        if (response.status === 200) {
          this.loadingSMTP = false;
          this.runToast("top-right", "success", "Reporte enviado correctamente");
          window.location.reload();
          //console.log('PDF enviado correctamente por correo.');
        } else {
          this.loadingSMTP = false;
          console.error('Error al enviar el PDF por correo.');
        }
      } catch (error) {
        this.runToast("top-right", "danger", "Error al previsualizar el reporte");
        //console.error('Error al previsualizar el reporte:', error);
      }
    },
    handleEdit(tickets) {
      this.observacionesEdit = tickets.observaciones;
      this.problemaEdit = tickets.problema;
      this.solucionEdit = tickets.solucion;
      this.selectTecEdit = tickets.idtecnico;
      this.selectEstadoEdit = tickets.estado;
      this.idticketEdit = tickets.idticket;
    },
    async actualizarTicket() {
      try {
        this.loadingSMTP = true;

        if (this.loadingSMTP) {
          this.runToast("top-right", "warning", "Cargando...");
        }

        const data = {
          observaciones: "",
          problema: this.problemaEdit,
          solucion: "",
          idtecnico: this.selectTecEdit,
          estado: this.selectEstadoEdit
        };

        if (this.observacionesEdit && this.observacionesEdit.trim() !== "") {
          data.observaciones = this.observacionesEdit;
        }
        if (this.solucionEdit && this.solucionEdit.trim() !== "") {
          data.solucion = this.solucionEdit;
        }
        // eslint-disable-next-line prettier/prettier
        if ([this.problemaEdit, this.selectTecEdit, this.selectEstadoEdit].every(field => field !== "")) {
          const response = await axios.put(`${this.apiUrl}/actualizar/ticket/${this.idticketEdit}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            this.loadingSMTP = false;
            //console.log("Ticket actualizado correctamente"); 
            this.runToast("top-right", "success", "Ticket actualizado correctamente");
            window.location.reload();
          } else {
            this.loadingSMTP = false;
            console.log("Hubo un error al actualizar el ticket");
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para la actualizacion");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
    async handleDelete(idticket) {
      const swalWithBootstrapButtons3 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      try {
        const result = await swalWithBootstrapButtons3.fire({
          title: "¿Estás seguro?",
          text: `No podrás revertir esta acción!`,
          showCancelButton: true,
          confirmButtonText: "Sí quiero eliminarlo!",
        });

        if (result.value) {
          const response = await axios.delete(
            `${this.apiUrl}/eliminar/ticket/${idticket}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
              },
            }
          );
          if (response.status === 200) {
            await swalWithBootstrapButtons3.fire({
              title: "Eliminado!",
              text: `Has eliminado el ticket con el número: ${idticket}`,
            });

            this.deleteRow(idticket);
          } else {
            console.error(
              "Error al eliminar el ticket: ",
              response.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error al mostrar el diálogo de confirmación: ", error);
      }
    },
    deleteRow(idticket) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.idticket === idticket
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    async obtenerTecnicos() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/tecnicos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectTec.tecnicos = response.data.map((tecnico) => ({
          value: tecnico.idtecnico,
          label: tecnico.nombre + ' ' + tecnico.apellido,
        }));
      } catch (error) {
        console.error("Error al cargar los tecnicos", error);
      }
    },
    async obtenerTickets() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/tickets`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.tableData = response.data.map((tickets) => ({
          idticket: tickets.idticket,
          poliza: tickets.poliza,
          fecha_creacion: this.formatoFecha(tickets.fecha_creacion),
          estado: tickets.estado,
          observaciones: tickets.observaciones,
          problema: tickets.problema,
          solucion: tickets.solucion,
          idcategoria: tickets.categoria_nombre,
          idcliente: tickets.cliente_nombre,
          idtecnico: tickets.tecnico_nombre + ' ' + tickets.tecnico_apellido,

          eliminarButton: {
            click: () => this.handleDelete(tickets.idticket),
          },
          editarTicket: {
            click: () => this.handleEdit(tickets),
          },
          editarSeguimiento: {
            click: () => this.handleSeguimiento(tickets.idticket),
          },
          opcionesPDF: {
            click: () => this.handlePDF(tickets),
          },
        }));
      } catch (error) {
        console.error("Error al cargar los tickets", error);
      }
    },
  },
};
</script>
<style></style>
