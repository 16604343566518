<!-- eslint-disable prettier/prettier -->
<template>
  <div class="row">
    <div class="col-12">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div style="display: flex; justify-content: space-between">
            <h4 class="card-title">Registro y control de clientes</h4>
            <n-button type="primary" round data-toggle="modal" data-target="#registroModal">Registrar cliente</n-button>
          </div>
        </template>

      <!-- Modal REGISTRO CLIENTES -->
        <div class="modal fade" id="registroModal" tabindex="-1" role="dialog" aria-labelledby="registroModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="registroModalLabel">Registrar Cliente</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6">
                      <fg-input v-model="nombre" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>
                      <fg-input v-model="rfc" label="RFC" type="text" placeholder="Ingresa tu RFC"></fg-input>
                      <fg-input v-model="razonSocial" label="Razon Social" type="text" placeholder="Ingresa tu Razón Social"></fg-input>
                      <fg-input v-model="regimen" label="Regimen Fiscal" type="text" placeholder="Ingresa tu Régimen Fiscal"></fg-input>
                      <fg-input v-model="email" label="Correo electrónico" type="email" placeholder="Ingresa tu correo electrónico"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <!-- Inputs para los teléfonos -->
                      <div v-for="(telefono, index) in telefonos" :key="index">
                        <fg-input v-model="telefonos[index]" :label="'Teléfono ' + (index + 1)" type="number" placeholder="Ingresa tu Teléfono"></fg-input>
                      </div>

                      <n-button v-if="mostrarBotonAgregar" type="default" round icon class="btn-sm" @click="agregarTelefono">
                        <i class="now-ui-icons ui-1_simple-add"></i>
                      </n-button>

                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Tipo de persona"
                            v-model="selectPersona.simple"
                          >
                            <el-option
                              v-for="option in selectPersona.persona"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="submitForm">Submit</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal -->

        <div>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <el-select
              class="select-primary mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <fg-input>
              <el-input
                type="search"
                class="mb-3"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </div>
          <el-table stripe style="width: 100%" :data="queriedData">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="135" fixed="right" label="Acciones">
              <template v-slot:default="props">
                <div class="table-actions mx-3">
                  <n-button
                    @click="props.row.editarCliente.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#updateModal"
                  >
                    <i class="fa fa-calendar"></i>
                  </n-button>
                  <n-button
                    @click="props.row.eliminarButton.click()"
                    class="remove"
                    type="danger"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <!-- Modal ACTUALIZAR CLIENTES -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateModalLabel">Actualizar Cliente</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!--Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!--Columna Izquierda-->
                    <div class="col-md-6">
                      <fg-input v-model="nombreEdit" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>
                      <fg-input v-model="rfcEdit" label="RFC" type="text" placeholder="Ingresa tu RFC"></fg-input>
                      <fg-input v-model="razonSocialEdit" label="Razon Social" type="text" placeholder="Ingresa tu Razón Social"></fg-input>
                      <fg-input v-model="regimenEdit" label="Regimen Fiscal" type="text" placeholder="Ingresa tu Régimen Fiscal"></fg-input>
                      <fg-input v-model="emailEdit" label="Correo electrónico" type="text" placeholder="Ingresa tu Correo electrónico"></fg-input>
                    </div>

                    <!--Columna Derecha-->
                    <div class="col-md-6">
                      <!-- Inputs para los teléfonos -->
                      <fg-input v-model="telefonoEdit1" label="Teléfono 1" type="text" placeholder="Ingresa tu Teléfono"></fg-input>
                      <fg-input v-model="telefonoEdit2" label="Teléfono 2" type="text" placeholder="Ingresa tu Teléfono"></fg-input>
                      <fg-input v-model="telefonoEdit3" label="Teléfono 3" type="text" placeholder="Ingresa tu Teléfono"></fg-input>

                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Selecciona un rol"
                            v-model="selectPersonaEdit"
                          >
                            <el-option
                              v-for="option in selectPersona.persona"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="actualizarCliente">Actualizar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal -->






        <template v-slot:footer>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </n-pagination>
          </div>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
export default {
  components: {
    NPagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    mostrarBotonAgregar() {
      return this.telefonos.length < 3;
    },
  },
  data() {
    return {
      telefonos: [""],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["nombre", "email", "rfc", "razon_social", "regimen", "telefono1", "tipo_persona"],
      tableColumns: [
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 100,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 100,
        },
        {
          prop: "rfc",
          label: "RFC",
          minWidth: 70,
        },
        {
          prop: "razon_social",
          label: "Razón Social",
          minWidth: 100,
        },
        {
          prop: "regimen",
          label: "Regimen",
          minWidth: 150,
        },
        {
          prop: "telefono1",
          label: "Telefono",
          minWidth: 100,
        },
        {
          prop: "tipo_persona",
          label: "Persona",
          minWidth: 80,
        },
      ],
      nombre: "",
      email: "",
      rfc: "",
      razonSocial: "",
      regimen: "",
      /*telefono1: "",
      telefono2: "",
      telefono3: "",*/
      selectPersona: {
        simple: "",
        persona: [
          { value: "fisica", label: "Física" },
          { value: "moral", label: "Moral" },
        ],
        multiple: "ARS",
      },
      selectArea: {
        simple: "",
        areas: [],
        multiple: "ARS",
      },
      nombreEdit: "",
      emailEdit: "",
      rfcEdit: "",
      razonSocialEdit: "",
      regimenEdit: "",
      telefonoEdit1: "",
      telefonoEdit2: "",
      telefonoEdit3: "",
      selectPersonaEdit: "",
      idclienteEdit: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  created() {
    this.apiUrl = getApiUrl();
    this.obtenerClientes();
  },
  methods: {
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    handleEdit(cliente) {
      this.nombreEdit = cliente.nombre;
      this.emailEdit = cliente.email;
      this.rfcEdit = cliente.rfc;
      this.razonSocialEdit = cliente.razon_social;
      this.regimenEdit = cliente.regimen;
      this.telefonoEdit1 = cliente.telefono1;
      this.telefonoEdit2 = cliente.telefono2;
      this.telefonoEdit3 = cliente.telefono3;
      this.selectPersonaEdit = cliente.tipo_persona;
      this.idclienteEdit = cliente.idcliente;
    },
    async actualizarCliente() {
      try {
        const data = {
          nombre: this.nombreEdit,
          email: this.emailEdit,
          rfc: this.rfcEdit,
          razon_social: this.razonSocialEdit,
          regimen: this.regimenEdit,
          telefono1: this.telefonoEdit1,
          telefono2: "",
          telefono3: "",
          tipo_persona: this.selectPersonaEdit,
        };

        if (this.telefonoEdit2 && this.telefonoEdit2.trim() !== "") {
          data.telefono2 = this.telefonoEdit2;
        }
        if (this.telefonoEdit3 && this.telefonoEdit3.trim() !== "") {
          data.telefono3 = this.telefonoEdit3;
        }

        //Verificar que los datos principales no esten vacios
        // eslint-disable-next-line prettier/prettier
        if ([this.nombreEdit, this.emailEdit, this.rfcEdit, this.razonSocialEdit, this.regimenEdit, this.telefonoEdit1, this.selectPersonaEdit].every(field => field !== "")) {
          const response = await axios.put(`${this.apiUrl}/actualizar/cliente/${this.idclienteEdit}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            console.log("Cliente actualizado correctamente");
            this.runToast("top-right", "success", "Cliente actualizado correctamente");
            window.location.reload();
          } else {
            console.log("Hubo un error al actualizar el cliente");
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
    async handleDelete(idcliente, nombre) {
      const swalWithBootstrapButtons3 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      try {
        const result = await swalWithBootstrapButtons3.fire({
          title: "¿Estás seguro?",
          text: `No podrás revertir esta acción!`,
          showCancelButton: true,
          confirmButtonText: "Sí quiero eliminarlo!",
        });

        if (result.value) {
          const response = await axios.delete(
            `${this.apiUrl}/eliminar/cliente/${idcliente}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          }
          );
          if (response.status === 200) {
            await swalWithBootstrapButtons3.fire({
              title: "Eliminado!",
              text: `Has eliminado a: ${nombre}`,
            });

            this.deleteRow(idcliente);
          } else {
            console.error(
              "Error al eliminar el técnico: ",
              response.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error al mostrar el diálogo de confirmación: ", error);
      }
    },
    deleteRow(idcliente) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.idcliente === idcliente
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    async obtenerClientes() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/clientes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.tableData = response.data.map((cliente) => ({
          nombre: cliente.nombre,
          email: cliente.email,
          rfc: cliente.rfc,
          razon_social: cliente.razon_social,
          regimen: cliente.regimen,
          telefono1: cliente.telefono1,
          telefono2: cliente.telefono2,
          telefono3: cliente.telefono3,
          tipo_persona: cliente.tipo_persona,
          idcliente: cliente.idcliente,
          eliminarButton: {
            click: () => this.handleDelete(cliente.idcliente, cliente.nombre),
          },
          editarCliente: {
            click: () => this.handleEdit(cliente),
          },
        }));
      } catch (error) {
        console.error("Error al cargar las areas", error);
      }
    },
    /*async obtenerAreas() {
      try {
        const response = await axios.get("/api/obtener-areas");
        this.selectArea.areas = response.data.map((area) => ({
          value: area.idarea,
          label: area.area,
        }));
      } catch (error) {
        console.error("Error al cargar las areas", error);
      }
    },*/
    agregarTelefono() {
      if (this.telefonos.length < 3) {
        this.telefonos.push("");
      }
    },
    async submitForm() {
      try {
        const formData = {
          nombre: this.nombre,
          email: this.email,
          rfc: this.rfc,
          razon_social: this.razonSocial,
          regimen: this.regimen,
          telefono1: this.telefonos[0],
          telefono2: this.telefonos[1] || "",
          telefono3: this.telefonos[2] || "",
          tipo_persona: this.selectPersona.simple,
        };
        // eslint-disable-next-line prettier/prettier
        if ([this.nombre, this.email, this.rfc, this.razonSocial, this.regimen, this.telefonos[0], this.selectPersona.simple].every(field => field !== "")) {
          const response = await axios.post(`${this.apiUrl}/register/clientes`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            this.runToast("top-right", "success", "Cliente registrado correctamente");
            window.location.reload();
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        this.runToast("top-right", "danger", "Error al registrar al cliente");
      }
    },
  },
};
</script>
<style></style>
