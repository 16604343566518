<!-- eslint-disable prettier/prettier -->
<template>
  <vnud-card>
    <template v-slot:header>
      <h5 class="title">Ticket</h5>
    </template>
    <form>
      <div class="row">
        <div class="col-md-5 d-flex flex-column">
          <label class="form-label mb-3">Escribe el número de póliza</label>
          <fg-input
            type="text"
            placeholder="Número de póliza"
            v-model="poliza"
          >
          </fg-input>
        </div>
        <div class="col-md-3 d-flex flex-column">
          <label class="form-label">Selecciona una categoría</label>
          <el-select
            class="select-primary"
            size="large"
            placeholder="Categoria"
            v-model="selectCat.simple"
          >
            <el-option
              v-for="option in selectCat.categorias"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 d-flex flex-column">
          <label class="form-label">Selecciona un técnico</label>
          <el-select
            class="select-primary"
            size="large"
            placeholder="Tecnico"
            v-model="selectTec.simple"
          >
            <el-option
              v-for="option in selectTec.tecnicos"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <fg-input label="Observaciones">
            <textarea
              class="form-control"
              placeholder="Escribe las observaciones"
              v-model="observaciones"
            >
            </textarea>
          </fg-input>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <fg-input label="Problema">
            <textarea
              class="form-control"
              placeholder="Escribe el problema"
              v-model="problema"
            >
            </textarea>
          </fg-input>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="d-flex align-items-center col-sm-12">
          <div v-for="(imagen, index) in imagenes" :key="index">
            <img :src="imagen.url" class="img-thumbnail" style="object-fit: cover; width: 200px; height: 200px; overflow: hidden;" alt="Imagen">
          </div>

          <input type="file" id="inputImagen" accept="image/*" style="display: none;" @change="agregarImagen"/>
          <n-button v-if="mostrarBotonAgregar && poliza" class="btn btn-info ml-3" @click="elegirImagen">Agregar Imagen</n-button>
        </div>
      </div>

      <div class="modal-footer justify-content-end">
        <n-button class="mr-2" type="primary" @click="submitTicket">Enviar ticket</n-button>
      </div>
    </form>
  </vnud-card>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElSelect, ElOption, ElUpload } from "element-plus";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElUpload.name]: ElUpload,
  },
  computed: {
    mostrarBotonAgregar() {
      return this.imagenes.length < 5;
    },
  },
  data() {
    return {
      loadingSMTP: false,
      poliza: "",
      observaciones: "",
      problema: "",
      selectCat: {
        simple: "",
        categorias: [],
        multiple: "ARS",
      },
      selectTec: {
        simple: "",
        tecnicos: [],
        multiple: "ARS",
      },
      imagenes: [],
    };
  },
  created() {
    this.apiUrl = getApiUrl();
    this.obtenerCategorias();
    this.obtenerTecnicos();
    //this.obtenerClientes();
  },
  methods: {
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    elegirImagen() {
      document.getElementById("inputImagen").click();
    },
    agregarImagen(e) {
      const archivo = e.target.files[0]; // Obtener el archivo seleccionado

      const numeroPoliza = this.poliza;
      const partesPoliza = numeroPoliza.split("-");
      const idCliente = partesPoliza[0];

      if (archivo) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const urlImagen = e.target.result;
          const fechaActual = new Date();
          const nombreImagen = `imagen_${idCliente}_${fechaActual.getTime()}.${archivo.name.split('.').pop()}`;
          this.imagenes.push({ nombre: nombreImagen, url: urlImagen });
        };
        reader.readAsDataURL(archivo);
      }
    },
    async obtenerCategorias() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/categorias`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectCat.categorias = response.data.map((categoria) => ({
          value: categoria.idcategoria,
          label: categoria.categoria,
        }));
      } catch (error) {
        console.error("Error al cargar las categorias", error);
      }
    },
    async obtenerTecnicos() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/tecnicos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectTec.tecnicos = response.data.map((tecnico) => ({
          value: tecnico.idtecnico,
          label: tecnico.nombre + " " + tecnico.apellido,
        }));
      } catch (error) {
        console.error("Error al cargar los tecnicos", error);
      }
    },
    async submitTicket() {
      try {
        this.loadingSMTP = true;

        if (this.loadingSMTP) {
          this.runToast("top-right", "warning", "Cargando...");
        }

        const numeroPoliza = this.poliza;
        const partesPoliza = numeroPoliza.split("-");
        const idCliente = partesPoliza[0];

        const ticketData = {
          poliza: this.poliza,
          observaciones: this.observaciones,
          problema: this.problema,
          idcategoria: this.selectCat.simple,
          idtecnico: this.selectTec.simple,
          idcliente: idCliente,
          imagen_1: this.imagenes[0] || "",
          imagen_2: this.imagenes[1] || "",
          imagen_3: this.imagenes[2] || "",
          imagen_4: this.imagenes[3] || "",
          imagen_5: this.imagenes[4] || "",
        };
        // eslint-disable-next-line prettier/prettier
        if ([this.poliza, this.observaciones, this.problema, this.selectCat.simple, this.selectTec.simple, this.idCliente].every(field => field !== "")) {
          const response = await axios.post(`${this.apiUrl}/register/tickets`, ticketData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            this.loadingSMTP = false;
            this.runToast("top-right", "success", "Ticket registrado correctamente");
            window.location.reload();
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data === 404) {
          this.runToast("top-right", "danger", "Error al registrar, verifica la poliza");

        } else {
          this.runToast("top-right", "danger", "Error al registrar el ticket");
        }
      }
    },
  },
};
</script>
<style></style>
