<!-- eslint-disable prettier/prettier -->
<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="col-md-4 ml-auto mr-auto">
      <vnud-card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/now-logo.png" alt="" />
            </div>
          </div>
        </template>

        <div>
          <vee-input
            name="email"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Correo"
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            placeholder="Contraseña"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" round block>
              Iniciar Sesión
            </n-button>
          </div>
        </template>
      </vnud-card>
    </div>
  </Form>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import getApiUrl from '@/util/apiConfig';
import Axios from "axios";
//import { useRouter } from "vue-router";
import router from "../../../router";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
export default {
  name: "vnud-login",
  components: {
    VeeInput,
    Form,
  },
  setup() {
    const apiUrl = getApiUrl();
    const runToast = (pos, type, ownText, ownIcon) => {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    };

    const onSubmit = async (values) => {
      //alert(JSON.stringify(values, null, 2));
      runToast("top-center", "success", "Iniciando sesion...");
      try {
        const response = await Axios.post(`${apiUrl}/login`, {
          email: values.email,
          password: values.password,
        });

        //let token = response.data.token;

        //Manejar respuesta del backend
        if (response.status === 200) {
          const { token, payload, expirationTime } = response.data;

          const rol = payload.rol;
          const rolesMap = {
            administrador: "91f5167c34c400758115c2a6826ec2e3",
            tecnico: "75f33e6eebce7012b8c74a889fa8a7ed",
          };
          const idtecnico = payload.idtecnico;
          //alert("Iniciaste sesion");
          localStorage.setItem("rol", rolesMap[rol]);
          localStorage.setItem("token", token);
          localStorage.setItem("time", expirationTime);
          localStorage.setItem("id", idtecnico);
          //const router = useRouter();
          //runToast("top-center", "success", "Iniciando sesion...");
          router.push("/dashboard");
        } else {
          alert("Error al iniciar sesion");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          runToast("top-center", "danger", "Credenciales incorrectas");
        } else {
          runToast("top-center", "danger", "Hubo un error al iniciar sesion");
        }
      }
    };

    /*async function onSubmit(values) {
      //alert(JSON.stringify(values, null, 2));
      try {
        const response = await Axios.post("/api/login", {
          email: values.email,
          password: values.password,
        });

        let token = response.data.token;

        //Manejar respuesta del backend
        if (response.status === 200) {
          //alert("Iniciaste sesion");
          localStorage.setItem("token", token);
          localStorage.setItem("time", response.data.expirationTime);
          //const router = useRouter();
          router.push("/dashboard");
        } else {
          alert("Error al iniciar sesion");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.runToast("top-center", "danger", "Credenciales incorrectas");
        } else {
          alert("Error al iniciar sesion");
        }
      }
    }*/

    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(1).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<!-- eslint-disable prettier/prettier -->
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
