<!-- eslint-disable prettier/prettier -->
<template>
  <div class="panel-header panel-header-lg">
    <!--<canvas :id="headerChartID"></canvas>-->
    <h1 style="text-align: center; color: white; font-weight: bold; font-size: 80px; margin-top: 35px;">FIXIT WEB</h1>
  </div>
</template>

<script>
/*import headerChart from "./HeaderChart";

export default {
  name: "overview-header",
  data() {
    return {
      headerChartID: "headerChart",
    };
  },
  mounted() {
    headerChart.createChart(this.headerChartID);
  },
};
*/
</script>
<style scoped></style>
