<!-- eslint-disable prettier/prettier -->
<template>
  <div class="row">
    <div class="col-12">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div style="display: flex; justify-content: space-between" >
            <h4 class="card-title">Registro y control de pólizas</h4>
            <div>
              <n-button type="primary" round data-toggle="modal" data-target="#registroModal">Registrar póliza</n-button>
              <n-button class="ml-3" type="success" round data-toggle="modal" @click="generarReporteExcel"><i class="fa fa-download" aria-hidden="true"></i> Descargar reporte pólizas</n-button>
            </div>
          </div>
        </template>

      <!-- Modal REGISTRO POLIZAS -->
        <div class="modal fade" id="registroModal" tabindex="-1" role="dialog" aria-labelledby="registroModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="registroModalLabel">Registrar Póliza</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6">
                      <!--<fg-input v-model="fecha_inicio" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>-->
                      <fg-input v-model="horas_contratadas" label="Horas a contratar" type="number" placeholder="Ingresa la cantidad de horas"></fg-input>
                      <fg-input v-model="descripcion" label="Descripcion" type="text" placeholder="Ingresa la descripcion de la póliza"></fg-input>
                      <fg-input v-model="observaciones" label="Observaciones" type="text" placeholder="Ingresa las observaciones de la póliza"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Categoria"
                            v-model="selectCat.simple"
                          >
                            <el-option
                              v-for="option in selectCat.categorias"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>

                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Cliente"
                            v-model="selectCliente.simple"
                          >
                            <el-option
                              v-for="option in selectCliente.clientes"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="submitForm">Enviar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- FIN Modal -->

        <div>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <el-select
              class="select-primary mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <fg-input>
              <el-input
                type="search"
                class="mb-3"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </div>
          <el-table style="width: 100%" :data="queriedData" :row-class-name="rowClassChange">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="135" fixed="right" label="Acciones">
              <template v-slot:default="props">
                <div class="table-actions">
                  <n-button
                    @click="props.row.opcionesPDF.click()"
                    class="edit"
                    type="info"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#pdfModal"
                  >
                    <i class="fa fa-file-pdf-o"></i>
                  </n-button>
                  <n-button
                    @click="props.row.editarPoliza.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#updateModal"
                  >
                    <i class="fa fa-calendar"></i>
                  </n-button>
                  <n-button
                    @click="props.row.eliminarButton.click()"
                    class="remove"
                    type="danger"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <!-- Modal ACTUALIZAR POLIZAS -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateModalLabel">Actualizar Póliza</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6">
                      <!--<fg-input v-model="fecha_inicio" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>-->
                      <!--<fg-input v-model="horas_contratadas" label="Horas a contratar" type="number" placeholder="Ingresa la cantidad de horas"></fg-input>-->
                      <fg-input v-model="descripcionEdit" label="Descripcion" type="text" placeholder="Ingresa la descripcion de la póliza"></fg-input>
                      <fg-input v-model="observacionesEdit" label="Observaciones" type="text" placeholder="Ingresa las observaciones de la póliza"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Categoria"
                            v-model="selectCatEdit"
                          >
                            <el-option
                              v-for="option in selectCat.categorias"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>

                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Cliente"
                            v-model="selectClienteEdit"
                          >
                            <el-option
                              v-for="option in selectCliente.clientes"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="actualizarPoliza">Actualizar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- FIN Modal -->




      <!-- Modal Opciones PDF -->
        <div class="modal fade" id="pdfModal" tabindex="-1" role="dialog" aria-labelledby="pdfModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="pdfModalLabel">Reporte de la póliza No. <b>{{ num_polizaPDF }}</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="flex text-center mt-2 mb-2">
                    <n-button class="mr-2" type="primary" @click="previsualizarReporte" size="lg" round><i class="fa fa-eye mr-1"></i> Ver reporte</n-button>
                    <n-button type="info" @click="enviarReporte" size="lg" round><i class="fa fa-envelope mr-1"></i> Enviar reporte</n-button>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- FIN Modal -->




        <template v-slot:footer>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </n-pagination>
          </div>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
export default {
  components: {
    NPagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      loadingSMTP: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["num_poliza", "idcliente", "fecha_inicio", "horas_contratadas", "idcategoria"],
      tableColumns: [
        {
          prop: "num_poliza",
          label: "Numero póliza",
          minWidth: 200,
        },
        {
          prop: "idcliente",
          label: "Cliente",
          minWidth: 200,
        },
        {
          prop: "fecha_inicio",
          label: "Fecha de inicio",
          minWidth: 200,
        },
        {
          prop: "horas_contratadas",
          label: "Horas contratadas",
          minWidth: 230,
        },
        {
          prop: "horas_restantes",
          label: "Horas restantes",
          minWidth: 200,
        },
        {
          prop: "descripcion",
          label: "Descripcion",
          minWidth: 200,
        },
        {
          prop: "observaciones",
          label: "Observaciones",
          minWidth: 200,
        },
        {
          prop: "idcategoria",
          label: "Categoria",
          minWidth: 150,
        },
      ],
      num_poliza: "",
      num_polizaPDF: "",
      fecha_inicio: "",
      horas_contratadas: "",
      descripcion: "",
      observaciones: "",
      horas_restantes: "",
      selectCat: {
        simple: "",
        categorias: [],
        multiple: "ARS",
      },
      selectCliente: {
        simple: "",
        clientes: [],
        multiple: "ARS",
      },
      descripcionEdit: "",
      observacionesEdit: "",
      selectCatEdit: "",
      selectClienteEdit: "",
      idpolizaEdit: "",
      idpolizaPDF: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  created() {
    this.apiUrl = getApiUrl();
    this.obtenerCategorias();
    this.obtenerClientes();
    this.obtenerPolizas();
  },
  methods: {
    formatoFecha(fechaHora) {
      const dateObj = new Date(fechaHora);
      const dia = dateObj.getDate();
      const mes = dateObj.getMonth() + 1; // Los meses son indexados desde 0
      const anio = dateObj.getFullYear();

      // Agregar ceros a la izquierda si es necesario
      const diaFormato = dia < 10 ? `0${dia}` : dia;
      const mesFormato = mes < 10 ? `0${mes}` : mes;

      return `${diaFormato}-${mesFormato}-${anio}`;
    },
    //Cambiar color de fila en tabla cuando las horas restantes sean menores a 10
    rowClassChange({ row }) {
      const horasPart = row.horas_restantes.split(":");
      const horasRest = parseInt(horasPart[0]);
      if (horasRest === 10) {
        return "table-warning";
      } else if (horasRest < 10) {
        return "table-danger";
      } else {
        return "";
      }
    },
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    handlePDF(idpoliza, num_poliza) {
      this.idpolizaPDF = idpoliza;
      this.num_polizaPDF = num_poliza;
    },
    async previsualizarReporte() {
      try {
        const response = await axios.post(`${this.apiUrl}/reporte/pdf-poliza/preview/${this.idpolizaPDF}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        // Obtener la URL del PDF desde la respuesta del backend
        const pdfUrl = response.data.pdfUrl;
        //console.log(pdfUrl)
        // Redireccionar al usuario para descargar o visualizar el PDF
        window.open(pdfUrl, '_blank');
      } catch (error) {
          console.error('Error al previsualizar el reporte:', error);
      }
    },
    async enviarReporte() {
      try {
        this.loadingSMTP = true;

        if (this.loadingSMTP) {
          this.runToast("top-right", "warning", "Cargando...");
        }

        const response = await axios.post(`${this.apiUrl}/reporte/pdf-poliza/enviar/${this.idpolizaPDF}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        if (response.status === 201) {
          this.loadingSMTP = false;
          this.runToast("top-right", "success", "Reporte enviado correctamente");
          window.location.reload();
          console.log('PDF enviado correctamente por correo.');
        } else {
          this.loadingSMTP = false;
          console.error('Error al enviar el PDF por correo.');
        }
      } catch (error) {
        this.runToast("top-right", "danger", "Error al previsualizar el reporte");
        //console.error('Error al previsualizar el reporte:', error);
      }
    },
    handleEdit(poliza) {
      this.descripcionEdit = poliza.descripcion;
      this.observacionesEdit = poliza.observaciones;
      this.selectCatEdit = poliza.idcategoria;
      this.selectClienteEdit = poliza.idcliente;
      this.idpolizaEdit = poliza.idpoliza;
    },
    async actualizarPoliza() {
      try {
        const data = {
          descripcion: this.descripcionEdit,
          //observaciones: this.observacionesEdit,
          idcategoria: this.selectCatEdit,
          idcliente: this.selectClienteEdit,
        };

        if (this.observacionesEdit && this.observacionesEdit.trim() !== "") {
          data.observaciones = this.observacionesEdit;
        }
        // eslint-disable-next-line prettier/prettier
        if ([this.descripcionEdit, this.selectCatEdit, this.selectClienteEdit].every(field => field !== "")) {
          const response = await axios.put(`${this.apiUrl}/actualizar/poliza/${this.idpolizaEdit}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            console.log("Poliza actualizada correctamente");
            this.runToast("top-right", "success", "Póliza actualizada correctamente");
            window.location.reload();
          } else {
            console.log("Hubo un error al actualizar la póliza");
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para la actualizacion");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
    async handleDelete(idpoliza, num_poliza) {
      const swalWithBootstrapButtons3 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      try {
        const result = await swalWithBootstrapButtons3.fire({
          title: "¿Estás seguro?",
          text: `No podrás revertir esta acción!`,
          showCancelButton: true,
          confirmButtonText: "Sí quiero eliminarlo!",
        });

        if (result.value) {
          const response = await axios.delete(
            `${this.apiUrl}/eliminar/poliza/${idpoliza}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          }
          );
          if (response.status === 200) {
            await swalWithBootstrapButtons3.fire({
              title: "Eliminado!",
              text: `Has eliminado la póliza: ${num_poliza}`,
            });

            this.deleteRow(idpoliza);
          } else {
            console.error(
              "Error al eliminar la poliza: ",
              response.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error al mostrar el diálogo de confirmación: ", error);
      }
    },
    deleteRow(idpoliza) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.idpoliza === idpoliza
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    async obtenerPolizas() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/polizas`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.tableData = response.data.map((poliza) => ({
          num_poliza: poliza.num_poliza,
          idcliente: poliza.cliente_nombre,
          fecha_inicio: this.formatoFecha(poliza.fecha_inicio),
          horas_contratadas: poliza.horas_contratadas,
          horas_restantes: poliza.horas_restantes,
          descripcion: poliza.descripcion,
          observaciones: poliza.observaciones,
          idcategoria: poliza.categoria_nombre,
          idpoliza: poliza.idpoliza,
          eliminarButton: {
            click: () => this.handleDelete(poliza.idpoliza, poliza.num_poliza),
          },
          editarPoliza: {
            click: () => this.handleEdit(poliza),
          },
          opcionesPDF: {
            click: () => this.handlePDF(poliza.idpoliza, poliza.num_poliza),
          },
        }));
      } catch (error) {
        console.error("Error al cargar las polizas", error);
      }
    },
    /*async obtenerAreas() {
      try {
        const response = await axios.get("/api/obtener-areas");
        this.selectArea.areas = response.data.map((area) => ({
          value: area.idarea,
          label: area.area,
        }));
      } catch (error) {
        console.error("Error al cargar las areas", error);
      }
    },*/
    async obtenerCategorias() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/categorias`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectCat.categorias = response.data.map((categoria) => ({
          value: categoria.idcategoria,
          label: categoria.categoria,
        }));
      } catch (error) {
        console.error("Error al cargar las categorias", error);
      }
    },
    async obtenerClientes() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/clientes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectCliente.clientes = response.data.map((cliente) => ({
          value: cliente.idcliente,
          label: cliente.nombre,
        }));
      } catch (error) {
        console.error("Error al cargar los clientes", error);
      }
    },
    async submitForm() {
      try {
        const formData = {
          horas_contratadas: this.horas_contratadas,
          descripcion: this.descripcion,
          //observaciones: this.observaciones,
          observaciones: "",
          idcategoria: this.selectCat.simple,
          idcliente: this.selectCliente.simple,
        };

        if (this.observaciones && this.observaciones.trim() !== "") {
          formData.observaciones = this.observaciones;
        }
        if ([this.horas_contratadas, this.descripcion, this.selectCat.simple, this.selectCliente.simple].every(field => field !== "")) {
          const response = await axios.post(`${this.apiUrl}/register/polizas`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });
          //console.log(response);
          if (response.status === 200) {
            this.runToast("top-right", "success", "Póliza registrada correctamente");
            console.log(response.status);
            window.location.reload();
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        this.runToast("top-right", "danger", "Error al registrar la póliza");
      }
    },
    async generarReporteExcel() {
      try {
        const response = await axios.post(`${this.apiUrl}/reporte/excel-poliza/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });

        if (response.data.filepath) {
        const excelUrl = response.data.filepath;
          console.log(excelUrl);  
          const downloadUrl = "http://localhost/fixit-reportes/reporte_polizas.xlsx"; 
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.target = '_blank'; 
            link.click();
        } else {
            this.runToast("top-right", "danger", "Hubo un error al descargar el archivo");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
  },
};
</script>
<style></style>
