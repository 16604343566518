<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="row">
      <div class="col-lg-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-primary">
            <i class="now-ui-icons media-2_sound-wave"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Gestion de Soporte Técnico</h5>
            <p class="description">
              Comienza a gestionar las solicitudes de soporte técnico.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mr-auto">
        <vnud-card class="card-signup text-center" no-footer-line>
          <template v-slot:header>
            <h4 class="card-title">Registrarse</h4>
          </template>

          <vee-input
            name="nombre"
            type="text"
            placeholder="Nombre..."
            addon-left-icon="now-ui-icons users_circle-08"
          />

          <vee-input
            name="apellido"
            type="text"
            placeholder="Apellido..."
            addon-left-icon="now-ui-icons text_caps-small"
          />

          <vee-input
            name="email"
            type="text"
            placeholder="Email..."
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            placeholder="Contraseña..."
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />

          <template v-slot:footer>
            <n-button type="primary" native-type="submit" round size="lg">
              Registrarse
            </n-button>
          </template>
        </vnud-card>
      </div>
    </div>
  </Form>
</template>
<script>
import { Checkbox, VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default {
  name: "vnud-register",
  components: {
    VeeInput,
    Form,
  },
  data() {
    return {
      agree: false,
    };
  },
  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const schema = Yup.object().shape({
      firstName: Yup.string().required().label("The First Name"),
      lastName: Yup.string().required().label("The Last Name"),
      email: Yup.string().email().required().label("The Email"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<style></style>
