<!-- eslint-disable prettier/prettier -->
<template>
  <div class="row">
    <div class="col-12">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div style="display: flex; justify-content: center">
            <h4 class="card-title">Filtro de mensajes</h4>
          </div>
        </template>

        <div>
          <div
            class="
              col-12
              d-flex
              justify-content-end
              flex-wrap
            "
          >
            <fg-input>
              <el-input
                type="search"
                clearable
                prefix-icon="el-icon-search"
                style="width: 300px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </div>
          
          <el-table style="width: 100%" :data="queriedData">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="135" fixed="right" label="Acciones">
              <template v-slot:default="props">
                <div class="table-actions">
                  <n-button
                    @click="props.row.actualizarTecnico.click()"
                    class="edit"
                    type="info"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-ticket"></i>
                  </n-button>
                  <n-button
                    @click="props.row.editarTicket.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#updateModal"
                  >
                    <i class="fa fa-inbox"></i>
                  </n-button>
                  <n-button
                    @click="props.row.eliminarButton.click()"
                    class="remove"
                    type="danger"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </vnud-card>
    </div>
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    queriedData() {
      if (!this.searchQuery) {
        return this.tableData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result;
    },
    
    total() {
      return this.queriedData.length;
    },
  },
  data() {
    return {
      loadingSMTP: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["idticket", "poliza", "fecha_creacion", "estado", "idcliente", "idtecnico"],
      tableColumns: [
        {
          prop: "idticket",
          label: "Num. ticket",
          minWidth: 155,
        },
        {
          prop: "poliza",
          label: "Num. poliza",
          minWidth: 200,
        },
        {
          prop: "fecha_creacion",
          label: "Fecha creacion",
          minWidth: 200,
        },
        {
          prop: "estado",
          label: "Estado",
          minWidth: 130,
        },
        {
          prop: "observaciones",
          label: "Observaciones",
          minWidth: 230,
        },
        {
          prop: "problema",
          label: "Problema",
          minWidth: 200,
        },
      ],
      selectTec: {
        simple: "",
        tecnicos: [],
        multiple: "ARS",
      },
      selectEstado: {
        simple: "",
        estados: [
          { value: "Abierto", label: "Abierto" },
          { value: "Pausado", label: "Pausado" },
          { value: "Cerrado", label: "Cerrado" },
        ],
        multiple: "ARS",
      },
      observacionesEdit: "",
      problemaEdit: "",
      solucionEdit: "",
      selectTecEdit: "",
      selectEstadoEdit: "",
      idticketEdit: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  created() {
    /*this.obtenerCategorias();
    this.obtenerClientes();
    this.obtenerPolizas();*/
    this.apiUrl = getApiUrl();
    this.obtenerTecnicos();
    this.obtenerTickets();
  },
  methods: {
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    /*handleLike(index, row) {
      const swalWithBootstrapButtons1 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons1.fire({
        title: `You liked ${row.name}`,
      });
    },*/
    handleEdit(tickets) {
      this.observacionesEdit = tickets.observaciones;
      this.problemaEdit = tickets.problema;
      this.solucionEdit = tickets.solucion;
      this.selectTecEdit = tickets.idtecnico;
      this.selectEstadoEdit = tickets.estado;
      this.idticketEdit = tickets.idticket;
    },
    async actualizarTicket() {
      try {
        this.loadingSMTP = true;

        if (this.loadingSMTP) {
          this.runToast("top-right", "warning", "Cargando...");
        }

        const data = {
          observaciones: "",
          problema: this.problemaEdit,
          solucion: "",
          idtecnico: this.selectTecEdit,
          estado: this.selectEstadoEdit
        };

        if (this.observacionesEdit && this.observacionesEdit.trim() !== "") {
          data.observaciones = this.observacionesEdit;
        }
        if (this.solucionEdit && this.solucionEdit.trim() !== "") {
          data.solucion = this.solucionEdit;
        }
        // eslint-disable-next-line prettier/prettier
        if ([this.problemaEdit, this.selectTecEdit, this.selectEstadoEdit].every(field => field !== "")) {
          const response = await axios.put(`${this.apiUrl}/actualizar/ticket/${this.idticketEdit}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            this.loadingSMTP = false;
            //console.log("Ticket actualizado correctamente");
            this.runToast("top-right", "success", "Ticket actualizado correctamente");
            window.location.reload();
          } else {
            this.loadingSMTP = false;
            console.log("Hubo un error al actualizar el ticket");
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para la actualizacion");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
    async handleDelete(idticket) {
      const swalWithBootstrapButtons3 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      try {
        const result = await swalWithBootstrapButtons3.fire({
          title: "¿Estás seguro?",
          text: `No podrás revertir esta acción!`,
          showCancelButton: true,
          confirmButtonText: "Sí quiero eliminarlo!",
        });

        if (result.value) {
          const response = await axios.delete(
            `${this.apiUrl}/eliminar/ticket/${idticket}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
              },
            }
          );
          if (response.status === 200) {
            await swalWithBootstrapButtons3.fire({
              title: "Eliminado!",
              text: `Has eliminado el ticket con el número: ${idticket}`,
            });

            this.deleteRow(idticket);
          } else {
            console.error(
              "Error al eliminar el ticket: ",
              response.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error al mostrar el diálogo de confirmación: ", error);
      }
    },
    deleteRow(idticket) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.idticket === idticket
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    async obtenerTecnicos() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/tecnicos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.selectTec.tecnicos = response.data.map((tecnico) => ({
          value: tecnico.idtecnico,
          label: tecnico.nombre + ' ' + tecnico.apellido,
        }));
      } catch (error) {
        console.error("Error al cargar los tecnicos", error);
      }
    },
    async obtenerTickets() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/tickets`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.tableData = response.data.map((tickets) => ({
          idticket: tickets.idticket,
          poliza: tickets.poliza,
          fecha_creacion: tickets.fecha_creacion,
          estado: tickets.estado,
          observaciones: tickets.observaciones,
          problema: tickets.problema,
          solucion: tickets.solucion,
          idcategoria: tickets.categoria_nombre,
          idcliente: tickets.cliente_nombre,
          idtecnico: tickets.tecnico_nombre + ' ' + tickets.tecnico_apellido,

          eliminarButton: {
            click: () => this.handleDelete(tickets.idticket),
          },
          editarTicket: {
            click: () => this.handleEdit(tickets),
          },
        }));
      } catch (error) {
        console.error("Error al cargar los tickets", error);
      }
    },
  },
};
</script>
