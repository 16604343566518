/* eslint-disable prettier/prettier */

export default {
    checkTokenExpiration() {
        const token = localStorage.getItem('token');
        const expirationTime = localStorage.getItem('time');

        if (token && expirationTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > expirationTime) {
            localStorage.removeItem('rol');
            localStorage.removeItem('token');
            localStorage.removeItem('time');
            return true; // Indica que el token ha expirado
        }
        }

        return false; // Indica que el token aún es válido
    }
};