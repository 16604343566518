import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import authService from "./services/authService";
import tokenService from "./services/tokenService";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import DashboardPlugin from "./dashboard-plugin";

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(DashboardPlugin);

//Middleware para verificar inicio de sesion antes de acceder a rutas
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !authService.isAuthenticated()) {
    // Si la ruta requiere autenticación y el usuario no está autenticado, redirigir al login
    next("/login");
  } else {
    // Continuar con la navegación normal
    next();
  }
});

//Checar si el token sigue vigente
if (tokenService.checkTokenExpiration()) {
  router.push("/login");
}

appInstance.mount("#app");
