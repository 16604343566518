/* eslint-disable prettier/prettier */

export default {
    isAdmin() {
        const rol = localStorage.getItem('rol');

        if (rol === "91f5167c34c400758115c2a6826ec2e3") {
          return true;
        } else {
          return false;
        }
        //Devuelve true si es rol admin y false si no lo es
    }
};
  