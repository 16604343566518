<!-- eslint-disable prettier/prettier -->
<template>
  <div class="row">
    <div class="col-12">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div style="display: flex; justify-content: space-between">
            <h4 class="card-title">Registro y control de técnicos</h4>
            <n-button type="primary" round data-toggle="modal" data-target="#registroModal">Registrar técnico</n-button>
          </div>
        </template>

      <!-- Modal REGISTRO TECNICOS -->
        <div class="modal fade" id="registroModal" tabindex="-1" role="dialog" aria-labelledby="registroModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="registroModalLabel">Registrar Técnico</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6">
                      <fg-input v-model="nombre" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>
                      <fg-input v-model="apellido" label="Apellido" type="text" placeholder="Ingresa tu apellido"></fg-input>
                      <fg-input v-model="email" label="Email address" type="email" placeholder="Enter email"></fg-input>
                      <fg-input v-model="password" label="Password" type="password" placeholder="Password"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Selecciona un rol"
                            v-model="selectRol.simple"
                          >
                            <el-option
                              v-for="option in selectRol.roles"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>

                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Selecciona un área"
                            v-model="selectArea.simple"
                          >
                            <el-option
                              v-for="option in selectArea.areas"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="submitForm">Enviar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal -->

        <div>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <el-select
              class="select-primary mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <fg-input>
              <el-input
                type="search"
                class="mb-3"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </div>
          <el-table stripe style="width: 100%" :data="queriedData">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="135" fixed="right" label="Acciones">
              <template v-slot:default="props">
                <div class="table-actions mx-3">
                  <!--<n-button
                    @click="props.row.actualizarTecnico.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-calendar"></i>
                  </n-button>-->
                  <n-button
                    @click="props.row.editarTecnico.click()"
                    class="edit"
                    type="warning"
                    size="sm"
                    round
                    icon
                    data-toggle="modal"
                    data-target="#updateModal"
                  >
                    <i class="fa fa-calendar"></i>
                  </n-button>
                  <n-button
                    @click="props.row.eliminarButton.click()"
                    class="remove"
                    type="danger"
                    size="sm"
                    round
                    icon
                  >
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <!-- Modal ACTUALIZAR TECNICOS -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateModalLabel">Actualizar Técnico</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Contenido del modal aquí -->
                <form method="#" action="#" @submit.prevent>
                  <div class="row">
                    <!-- Columna Izquierda -->
                    <div class="col-md-6">
                      <fg-input v-model="nombreEdit" label="Nombre" type="text" placeholder="Ingresa tu nombre"></fg-input>
                      <fg-input v-model="apellidoEdit" label="Apellido" type="text" placeholder="Ingresa tu apellido"></fg-input>
                      <fg-input v-model="emailEdit" label="Email address" type="email" placeholder="Enter email"></fg-input>
                      <fg-input v-model="passwordEdit" label="Password" type="password" placeholder="Password"></fg-input>
                    </div>

                    <!-- Columna Derecha -->
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Selecciona un rol"
                            v-model="selectRolEdit"
                          >
                            <el-option
                              v-for="option in selectRol.roles"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>

                        <div class="col-md-6 pt-3">
                          <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Selecciona un área"
                            v-model="selectAreaEdit"
                          >
                            <el-option
                              v-for="option in selectArea.areas"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-end">
                <n-button class="mr-2" type="primary" @click="actualizarTecnico">Actualizar</n-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal -->






        <template v-slot:footer>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </n-pagination>
          </div>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Swal from "sweetalert2";
import getApiUrl from '@/util/apiConfig';
import axios from "axios";
import { json } from "d3";
export default {
  components: {
    NPagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["nombre", "apellido", "email", "idarea"],
      tableColumns: [
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 150,
        },
        {
          prop: "apellido",
          label: "Apellidos",
          minWidth: 200,
        },
        {
          prop: "idarea",
          label: "Area",
          minWidth: 100,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 200,
        },
      ],
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      selectRol: {
        simple: "",
        roles: [
          { value: "tecnico", label: "Técnico" },
          { value: "administrador", label: "Administrador" },
        ],
        multiple: "ARS",
      },
      selectArea: {
        simple: "",
        areas: [],
        multiple: "ARS",
      },
      nombreEdit: "",
      apellidoEdit: "",
      emailEdit: "",
      passwordEdit: "",
      selectRolEdit: "",
      selectAreaEdit: "",
      idtecnicoEdit: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  created() {
    this.apiUrl = getApiUrl();
    this.obtenerAreas();
    this.obtenerTecnicos();
  },
  methods: {
    //mostrar notificaciones
    runToast(pos, type, ownText, ownIcon) {
      const text =
        "Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    /*handleLike(index, row) {
      const swalWithBootstrapButtons1 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons1.fire({
        title: `You liked ${row.name}`,
      });
    },*/
    handleEdit(tecnico) {
      this.nombreEdit = tecnico.nombre;
      this.apellidoEdit = tecnico.apellido;
      this.emailEdit = tecnico.email;
      this.selectRolEdit = tecnico.rol;
      this.selectAreaEdit = tecnico.idarea;
      this.idtecnicoEdit = tecnico.idtecnico;
    },
    async actualizarTecnico() {
      try {
        const data = {
          nombre: this.nombreEdit,
          apellido: this.apellidoEdit,
          email: this.emailEdit,
          rol: this.selectRolEdit,
          idarea: this.selectAreaEdit,
        };

        if (this.passwordEdit.trim() !== "") {
          data.password = this.passwordEdit;
        }

        // eslint-disable-next-line prettier/prettier
        if ([this.nombreEdit, this.apellidoEdit, this.emailEdit, this.selectRolEdit, this.selectAreaEdit].every(field => field !== "")) {
          const response = await axios.put(`${this.apiUrl}/actualizar/tecnico/${this.idtecnicoEdit}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });

          if (response.status === 200) {
            console.log("Tecnico actualizado correctamente");
            this.runToast("top-right", "success", "Tecnico actualizado correctamente");
            window.location.reload();
          } else {
            console.log("Hubo un error al actualizar el tecnico");
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        console.error("Error al enviar la solicitud de actualización: ", error);
      }
    },
    async handleDelete(idtecnico, nombre) {
      const swalWithBootstrapButtons3 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      try {
        const result = await swalWithBootstrapButtons3.fire({
          title: "¿Estás seguro?",
          text: `No podrás revertir esta acción!`,
          showCancelButton: true,
          confirmButtonText: "Sí quiero eliminarlo!",
        });

        if (result.value) {
          const response = await axios.delete(
            `${this.apiUrl}/eliminar/tecnico/${idtecnico}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          }
          );
          if (response.status === 200) {
            await swalWithBootstrapButtons3.fire({
              title: "Eliminado!",
              text: `Has eliminado a: ${nombre}`,
            });

            this.deleteRow(idtecnico);
          } else {
            console.error(
              "Error al eliminar el técnico: ",
              response.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error al mostrar el diálogo de confirmación: ", error);
      }
    },
    deleteRow(idtecnico) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.idtecnico === idtecnico
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    async obtenerTecnicos() {
      try {
        //const response = await api.get('/obtener/tecnicos');
        const response = await axios.get(`${this.apiUrl}/obtener/tecnicos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        this.tableData = response.data.map((tecnico) => ({
          nombre: tecnico.nombre,
          apellido: tecnico.apellido,
          idarea: tecnico.area_nombre,
          email: tecnico.email,
          idtecnico: tecnico.idtecnico,
          eliminarButton: {
            click: () => this.handleDelete(tecnico.idtecnico, tecnico.nombre),
          },
          editarTecnico: {
            click: () => this.handleEdit(tecnico),
          },
        }));
      } catch (error) {
        console.error("Error al cargar los tecnicos", error);
      }
    },
    async obtenerAreas() {
      try {
        const response = await axios.get(`${this.apiUrl}/obtener/areas`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        });
        //const response = await api.get('/obtener/areas');
        //console.log (response.data);
        if (Array.isArray(response.data)) {
      this.selectArea.areas = response.data.map((area) => ({
        value: area.idarea,
        label: area.area,
      }));
    } else {
      console.error('Error: response.data no es un array', response.data);
    }
      } catch (error) {
        console.error("Error al cargar las areas", error);
      }
    },
    async submitForm() {
      try {
        const formData = {
          nombre: this.nombre,
          apellido: this.apellido,
          email: this.email,
          password: this.password,
          rol: this.selectRol.simple,
          idarea: this.selectArea.simple,
        };

        if ([this.nombre, this.apellido, this.email, this.password, this.selectRol.simple, this.selectArea.simple].every(field => field !== "")) {
          const response = await axios.post(`${this.apiUrl}/register/tecnicos`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            },
          });
          //console.log(response);
          if (response.status === 200) {
            this.runToast("top-right", "success", "Tecnico registrado correctamente");
            //console.log(response.status);
            window.location.reload();
          }
        } else {
          this.runToast("top-right", "danger", "Faltan datos para el registro");
        }
      } catch (error) {
        this.runToast("top-right", "danger", "Error al registrar el usuario");
      }
    },
  },
};
</script>
<style></style>
