/* eslint-disable prettier/prettier */

export default {
    isAuthenticated() {
        const token = localStorage.getItem('token');
        if (token) {
            const tokenParts = token.split('.');

            if (tokenParts.length === 3) {
                return true;
            } else {
                return false;
            }
        }
        return false;
        //return !!token; // Devuelve true si hay un token, false si no lo hay
    }
};
  